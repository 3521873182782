// NPM packages
import React from 'react';
import { styled } from '@mui/system';
import { Routes, Route, Navigate } from 'react-router-dom';

// All other imports
import NavItem from 'types/NavItem';
import { default as UnstyledHeader } from './header/Header';
import { default as UnstyledFooter } from './footer/Footer';
import Home from 'components/pages/Home';
import SignUp from 'components/pages/SignUp';
import Projects from './pages/projects/Projects';
import ResendVerification from 'components/pages/ResendVerification';
import NotFound from 'components/pages/NotFound';
import ComingSoon from 'components/pages/ComingSoon';
import VerifyEmail from 'components/pages/VerifyEmail';
import ForgotPassword from 'components/pages/ForgotPassword';
import ResetPassword from 'components/pages/ResetPassword';
import ProjectDetails from 'components/pages/projectDetails/ProjectDetails';
import AuthGuard from 'components/core/AuthGuard';
import GlobalErrorBar from 'components/core/GlobalErrorBar';
import ConfirmationModal from 'components/core/ConfirmationModal';
import Disclaimer from 'components/pages/Disclaimer';
import EditProject from 'components/pages/EditProject';
import Metrics from 'components/pages/metrics/Metrics';
import Profile from 'components/pages/Profile';
import StaticPage from 'components/pages/StaticPage';
import ReviewNewUser from 'components/pages/ReviewNewUser';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  fontFamily: "'Roboto', sans-serif",
  fontSize: '1.2rem',
  fontWeight: 400,
});
const Header = styled(UnstyledHeader)({
  flex: '0 0 auto',
});
const Body = styled('div')({
  flex: '1 0 auto',
});
const Footer = styled(UnstyledFooter)({
  flex: '0 0 auto',
});

export interface AppProps {
  className?: string;
}

const headerItems: NavItem[] = [
  {
    text: 'About',
    href: 'sb/about',
  },
  {
    text: 'Events',
    href: 'sb/events',
  },
  {
    text: 'Policies',
    href: 'sb/policies',
  },
  {
    text: 'Metrics',
    href: '/metrics',
  },
  {
    text: 'Acknowledgement',
    href: 'sb/acknowledgement',
  },
];

export default function App(props: AppProps): React.ReactElement | null {
  const { className } = props;
  return (
    <Root className={className}>
      <GlobalErrorBar />
      <ConfirmationModal />
      <AuthGuard>
        <Routes>
          <Route
            path="/sb-headless/*"
            element={
              <Body>
                <StaticPage />
              </Body>
            }
          />
          <Route
            path="*"
            element={
              <>
                <Header items={headerItems} />
                <Body>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route path="/approve-user" element={<ReviewNewUser />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/disclaimer" element={<Disclaimer />} />
                    <Route
                      path="/resend-verification"
                      element={<ResendVerification />}
                    />
                    <Route path="/confirm-code" element={<VerifyEmail />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/projects/edit/:id" element={<EditProject />} />
                    <Route path="/my-msd" element={<ComingSoon />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/projects/:id" element={<Navigate to="info" />} />
                    <Route path="/projects/:id/info" element={<ProjectDetails />} />
                    <Route path="/projects/:id/current" element={<ProjectDetails />} />
                    <Route path="/projects/:id/pending" element={<ProjectDetails />} />
                    <Route path="/metrics" element={<Metrics />} />
                    <Route path="/sb/*" element={<StaticPage />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Body>
                <Footer />
              </>
            }
          />
        </Routes>
      </AuthGuard>
    </Root>
  );
}