// NPM packages
import styled from '@emotion/styled';
import { StoryblokComponent, useStoryblok } from '@storyblok/react';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// All other imports
import NotFoundContent from 'components/core/NotFoundContent';
import CircularProgress from 'components/core/CircularProgress';

const Root = styled.div({
  overflow: 'visible',
});

export interface StaticPageProps {
  className?: string;
}

// https://ericdcobb.medium.com/scrolling-to-an-anchor-in-react-when-your-elements-are-rendered-asynchronously-8c64f77b5f34
const useHashScroll = (): void => {
  const scrollRef = useRef(false);
  const hash = useLocation().hash;
  const hashRef = useRef(hash);

  useEffect(() => {
    if (hash) {
      if (hashRef.current !== hash) {
        hashRef.current = hash;
        scrollRef.current = false;
      }
      if (!scrollRef.current) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          scrollRef.current = true;
        }
      }
    }
  });
};

export default function StaticPage(
  props: StaticPageProps
): React.ReactElement | null {
  const { className } = props;
  const webappPathname = useLocation().pathname;
  const isDraftVersion = process.env.NODE_ENV !== "production";
  // const storyblokPathname = webappPathname.replace('/sb/', '');
  const storyblokPathname = webappPathname.replace(/\/sb(-headless)?\//, '');


  const story = useStoryblok(storyblokPathname!, {
    version: isDraftVersion ? 'draft' : 'published',
    resolve_relations: [
      'resourceCategoryList.resourceCategories',
      'textResource.authors',
      'videoResource.authors',
    ],
  });

  useHashScroll();

  let body = <CircularProgress text={'Loading...'} />;

  if (story.content) {
    const displayNotFound =
      webappPathname.startsWith('/sb/people') || story.content.displayNotFound;
    if (displayNotFound) {
      body = <NotFoundContent />;
    } else {
      body = <StoryblokComponent blok={story.content} />;
    }
  }

  return <Root className={className}>{body}</Root>;
}
